@use "../helpers/variables";

/* --------------------------------- */
/* WAI SHORTCUTS
------------------------------------ */

nav {
  ul {
  &.wai-nav {
    li {
      a {
        color: variables.$grey-light !important;
        &.skip-main {
          display: inline-block;
          left: -999rem;
          top: auto;
          position: fixed;
          width: 0;
          height: 0;
          overflow: hidden;
          z-index: -999;
          &:focus {
            background-color: variables.$blue !important;
            color: variables.$grey-light !important;
            left: 0.5rem;
            top: 0.5rem;
            width: auto;
            height: auto;
            overflow: auto;
            padding: 0.5rem 0.8rem;
            z-index: 999;
            box-shadow: 0 0 15px 0 rgba(0,0,0,0.25);
            }
          }
        }
      }
    }
  }
}
