@use "../helpers/variables";

/* --------------------------------- */
/* TEXT CARDS FOR ELECTIONS HOMEPAGE
------------------------------------ */

.elections-block {
  a {
    color: variables.$blue;
    text-decoration: underline;
    text-decoration-thickness: 1px;
    text-underline-offset: 6px;
    &:hover, &:focus {
      text-decoration-thickness: 2px;
      text-underline-offset: 6px;
      outline-offset: 1px;
    }
    &:focus {
      outline: solid 2px variables.$blue-focus;
      outline-offset: 4px;
    }
  }
}