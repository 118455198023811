@use "../helpers/mixins";
@use "../helpers/variables";

/* SELECT DROPDOWN ==============================================================
 =================================================================================== */

label {
  &.dropdown {
    select {
      -webkit-appearance: none;
      appearance: none;
    }

    &:after {
      position: absolute;
      content: "";
      top: 50%;
      right: 16px;
      width: 18px;
      height: 18px;
      border-radius: 2px;
      transform: rotate(45deg) translate(-50%, -50%);
      background-color: transparent;
      border-width: 3px;
      border-style: solid;
      pointer-events: none;
      @include mixins.transition(.3s);
    }

    &.yellow-arrow {
      &:after {
        border-color: transparent variables.$yellow variables.$yellow transparent;
      }
    }

    &.blue-arrow {
      &:after {
        border-color: transparent variables.$blue-middle variables.$blue-middle transparent;
      }
    }
  }
}