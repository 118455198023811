@use "../helpers/variables";

/* --------------------------------- */
/* CONTAINER IFRAME VIDEO
------------------------------------ */

.figure-video {
  &.cursor-pointer:hover,
  &.cursor-pointer:focus {
    outline-offset: 2px;
    outline-color: variables.$blue-focus;
    .figcaption {
      span {
        text-decoration: underline;
        transform: translateX(10px);
      }
    }
    .iframe-container {
      position: relative;
      width: 100%;
      height: 100%;
      aspect-ratio: 16/9;
      .thumbnail-video-wrapper {
        .link-image {
          .thumbnail-video {
            .wrapper-picture {
              .background {
                background-size: 104%;
              }
            }
          }
        }
      }
    }
  }
  .iframe-container {
  overflow: hidden;
  position: relative;
  &.landscape {
    padding-top: 56.25%;
  }
  .thumbnail-video-wrapper {
    transition: all .3s ease-in-out;
  }
  .link-image {
    .thumbnail-video {
      .wrapper-picture {
        .background {
          background-size: 102%;
          transition: all .3s ease-in-out;
        }
      }
    }
  }
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    vertical-align: middle;
    width: 100%;
    height: 100%;
  }
}
  .figcaption {
    /*&::before {
      content: "";
      position: absolute;
      top: 50%;
      left: 26px;
      transform: translate(-50%, -50%);
      display: block;
      z-index: 20;
      width: 0;
      height: 0;
      border-color: transparent transparent transparent $black;
      border-style: solid;
      border-width: 8px 0 8px 16px;
      margin-left: 2px;
      transition: all .3s ease-in-out;
    }*/
  }
}

.linktovideo{
  &:hover{
    figure{
      span{
        transform: scale(1.1);
      }
    }
  }
  &:focus{
    figure{
      span{
        outline: 3px dotted #fff;
        outline-offset: -3px;
      }
    }
  }
}
.player-ico {
  z-index: 1;
  position: absolute;
  top: 50%;
  left: 50%;
  height: 108px;
  width: 108px;
  border: 2px solid hsla(0,0%,100%,.2);
  background-color: variables.$blue-focus;
  transform: translate(-50%,-50%);
  border-radius: 50%;
  padding: 20px;
  transition: all .3s ease-in-out;
  .arrow {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1024 1024' width='36' height='36'%3E%3Cpath fill='%23fff' d='M796.806 461.202c44.919 28.075 44.739 73.706 0 101.668L337.334 850.041C292.415 878.116 256 857.956 256 804.736v-585.4c0-53.096 36.595-73.266 81.334-45.305l459.472 287.171z'/%3E%3C/svg%3E");
    display: inline-block;
    font-size: 0;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
  }
}

.transcript-btn {
  display: flex;
  align-items: center;
  svg {
    max-width: 25px !important;
    height: 25px !important;
    margin-right: 10px;
    border-radius: 100%;
    border: solid 2px variables.$blue-arrow;
  }
  span {
    text-underline-offset: 4px;
  }
  &:hover {
    span {
      text-decoration-thickness: 2px;
    }
  }
  &:focus {
    outline: solid 2px variables.$blue-focus;
    outline-offset: 4px;
    span {
    text-decoration-thickness: 2px;
    }
  }
}

.video-list {
  list-style: none !important;
  .video-item {
    list-style: none !important;
  }
}