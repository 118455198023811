@use "../helpers/variables";

/* Tooltip */
.tooltip{
  clip: rect(0, 0, 0, 0);
  transform: translateY(20px);
  opacity: 0;
  transition: transform .3s ease-in-out, opacity .3s ease-in;
}

.label-tooltip{
  cursor: pointer;
  &:hover, &:focus{
    .tooltip{
      display: inline-block;
      border-radius: 4px;
      z-index: 100;
      top: calc(100% + 10px);
      left: 24%;
      width: max-content;
      transform: translateY(0);
      opacity: 1;

      /* position: static;*/
      height: auto;
      overflow: visible;
      clip: auto;
      white-space: normal;
      &:after, &:before {
        bottom: 100%;
        left: 6%;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
      }
      &:after {
        border-color: rgba(255, 255, 255, 0);
        border-bottom-color: #fff;
        border-width: 8px;
        margin-left: -8px;
      }
      &:before {
        border-color: rgba(0, 0, 0, 0);
        border-bottom-color: variables.$grey-middle;
        border-width: 9px;
        margin-left: -9px;
      }
      .list-none{
        li{
          &:before{
            content: "\2022";
            color: #1e68fa;
            font-weight: bold;
            display: inline-block;
            width: 1em;
            margin-left: -1em;
          }
        }
      }
      @media screen and (min-width: 550px){
        .list-none{
          font-size: .8rem;
        }
      }
      @media screen and (max-width: 550px){
        font-size: .6rem;
        left: -30%;
        width: 300px;
        right: 100%;
        &:after, &:before {
          left: 18%;
        }
      }
      @media screen and (max-width: 460px){
        left: -30%;
        width: 220px;
        right: 100%;
        &:after, &:before {
          left: 18%;
        }
      }
      @media screen and (max-width: 380px){
        left: -160%;
        width: 220px;
        right: 100%;
        &:after, &:before {
          left: 48%;
        }
      }
    }
  }
}