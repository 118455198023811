/* Font variables */

$EPGammaRegular: 'EPGammaRegular', Helvetica, Arial, sans-serif;
$EPGammaBold: 'EPGammaBold', Helvetica, Arial, sans-serif;
$EPGammaNarrowUltra: 'EPGammaNarrowUltra', Helvetica, Arial, sans-serif;
$EPGammaNarrowExtraBold: 'EPGammaNarrowExtraBold', Helvetica, Arial, sans-serif;
$EuropeaVar: 'EuropeaVar', Helvetica, Arial, sans-serif;

/* Color variables generic */

$white: #ffffff;
$lightRose: #FFF3F2;
$black: #000000;
$blackGreyTxt: #505154;
$blue: #024EA2;
$blue-link: #024EA2;
$blue-middle: #024EA2;
$blue-focus: #1E6CFF;
$blue-utv-baseline: #1B9FD0;
$blue-arrow: #004DA0;
$green: #008400;
$red: #ce2d3c;
$red-warning: #ED0100;
$bgFieldError: #F9A3A3;
$grey-middle: #BFBFBF;
$grey-darker: #1a1a3d;
$grey-dark: #1E1E1F;
$grey-text: #6E6E6E;
$grey-border: #BDBDBD;
$grey-bg: #D9D9D9;
$grey-light: #F8F8F8;
$yellow: #FDE021;
$yellow-lighter: #FFF200;