@use "../helpers/mixins";
@use "../helpers/variables";

//Checkbox

.checkbox{
  label{
    span{
      flex: 2;
    }
  }
  input[type='checkbox']{
    &:after{
      content: "";
      margin-left: 7px;
      margin-bottom: 4px;
      width: 4px;
      height: 9px;
      border: solid variables.$blue;
      border-width: 0 2px 2px 0;
      transform: rotate(45deg) scale(0);
      display: inline-block;
      @include mixins.transition(.3s);
    }
  }
  input[type=checkbox] {
    &:checked {
      &:after{
        transform: rotate(45deg) scale(1);
      }
    }
    &:focus{
      box-shadow: 0 0 6px variables.$blue;
    }
  }
  &.has-error{
    input[type=checkbox]{
      border: 1px solid red;
    }
  }
}