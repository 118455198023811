/* Font Family */
@font-face {
  font-family: 'EPGammaRegular';
  src: url('../fonts/Europea-Regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'EuropeaSemiBold';
  src: url('../fonts/Europea-Semibold.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'EuropeaMedium';
  src: url('../fonts/Europea-Medium.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'EPGammaBold';
  src: url('../fonts/Europea-Bold.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'EPGammaNarrowUltra';
  src: url('../fonts/EuropeaNarrow-Ultra.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'EPGammaNarrowExtraBold';
  src: url('../fonts/EuropeaNarrow-Extrabold.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'EuropeaVar';
  src: url('../fonts/EuropeaVar.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}
